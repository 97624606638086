
#highlight-achievement {
  padding: 3svw;
  height: inherit;
  background-color: #f9f9f9;

  .highlight-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .highlight-title{
    text-align: center;
  }

  .section-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #333;

    .highlight {
      color: #ff4081;
    }
  }

  .summary-container {
    margin: 2rem auto auto 2rem;

    .summary-text {
      font-size: 1.2rem;
      color: #555;
      margin-bottom: 15px;
    }

    .achievements-list {
      list-style-type: none;
      padding: 0;

      li {
        font-size: 1rem;
        margin-bottom: 10px;
        color: #333;
        line-height: 1.5;
      }
    }
  }

  .video-container {
    margin: 30px 0;

    iframe {
      border: none;
      border-radius: 8px;
    }
  }

  .pdf-container {
    margin: 30px 0;

    h3 {
      font-size: 1.8rem;
      color: #333;
      margin-bottom: 10px;
    }

    p {
      font-size: 1rem;
      color: #555;
    }

    .pdf-highlights {
      list-style-type: disc;
      margin: 15px auto;
      text-align: left;
      max-width: 800px;

      li {
        font-size: 1rem;
        margin-bottom: 10px;
        color: #333;
        line-height: 1.5;
      }
    }

    .pdf-link {
      font-size: 1.2rem;
      color: #ff4081;
      text-decoration: underline;
    }
  }
}
