@import '../../variables.scss';

.projects-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  align-items:center;
  width: 100%;
  padding: 20px;
  
  @media (max-width: 768px) {
      align-items: center;
      justify-content: normal !important;
  }
}

.MuiCard-root {
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.2);
  }
}
