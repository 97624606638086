@import '../../variables.scss';

.active {
  color: $silver-color;
  padding-bottom: 8px;
  border-bottom: 3px solid $silver-color;
}

.active-menu-icon {
  color: $blue-color;
  padding-bottom: 5px;
  border-bottom: 3px solid $blue-color;
}


.navbar {
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  
    &.hidden {
      transform: translateY(-100%);
      opacity: 0;
    }
  
    &.visible {
      transform: translateY(0);
      opacity: 1;
    }
  
    .toolbar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 16px;
    }
  
    .desktop-menu {
      display: flex;
      gap: 20px;
  
      .nav-link {
        text-decoration: none;
        color: black;
        font-size: 1rem;
        font-weight: 500;
        cursor: pointer;
  
        &:hover {
          color: #007bff;
        }
  
        &.active {
          color: #007bff;
          border-bottom: 2px solid #007bff;
        }
      }
    }
  
    .mobile-menu {
      display: none;
    }
  
    @media (max-width: 768px) {
      .desktop-menu {
        display: none;
      }
  
      .mobile-menu {
        display: block;
      }
    }
  
    .drawer-link {
      text-decoration: none;
      color: black;
      font-size: 1rem;
  
      &:hover {
        color: #007bff;
      }
    }
  }
  