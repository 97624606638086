.video-container {
  position: relative;
  width: 100%; // Full width of the parent container
  padding-bottom: 56.25%; // Aspect ratio for 16:9 videos
  height: 0;
  overflow: hidden;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; // Responsive width
    height: 90%; // Responsive height
    border: 0;
  }
}
