.about-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    align-items:center;
    width: 100%;
    padding: 20px;
    
    @media (max-width: 768px) {
        align-items: center;
        justify-content: normal !important;
    }
}

.aboutPara {
    font-size: 1.5rem;
    font-weight: normal;
    padding: 5px 0;
    max-width: 700px;
    letter-spacing: 0.2px;
    line-height: 1.6;
    color: #0101016d;

    @media (max-width: 768px) {
        font-size: 20px;
    }
}

.tech-stack{
    display: 'inline-block';
    font-weight: 'bold';
    font-size: '1.8rem';
    min-width: '6rem';
}

