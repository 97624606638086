.menu-content {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    overflow-y: scroll;
    height: calc(100vh - 35px);
}
  
// .section-container {
//     height: 80svh;
//     min-height: 80svh;
//     justify-content: center;
//     align-items: center;
//     overflow: hidden;
//     width: 100%;
// }
  