@import '../variables.scss';

section{
    position: relative;
    top: 5rem;
}

.section-title {
    font-size: 90px;
    font-weight: bold;
    padding: 25px 0 25px 0;
    align-items: center;
    justify-content: center;
    display: flex;

    @media (max-width: 768px) {
        font-size: 55px;
        padding: 10px 0 10px 0;
    }
}

.pink-title {
    color: $blue-color;
}