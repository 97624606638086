@import '../../variables.scss';

.contact-wrapper {
    flex-direction: column;
    display: flex;

    @media (max-width: 768px) {
        padding: 20px;
    }
}

.links-container {
    flex-direction: row;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 768px) {
        // flex-direction: column;
        align-items: center;
    }
}

.link-image {
    width: 80px;
    height: 80px;
    margin: 15px;

    @media (max-width: 768px) {
        width: 60px;
        height: 60px;
        margin: 10px;
    }

    &:hover {
        transform: scale(1.2);
    }
}
