.app-warpper {
    height: 100%;
    display: flex;
    flex-direction: column;
    background-size: cover;
    background-position: center;
}


.text{
    font-weight: 300 !important;
}

.sub-typed-text{
    color: #0101013d;
    font-size:5.2rem;
    font-weight: 100;
}
