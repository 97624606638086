@import '../../variables.scss';

.home-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  padding: 0 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    padding: 20px;
  }
}

.home-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  width: 50%;
  max-width: 600px;

  @media (max-width: 768px) {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }
}

.text-hello {
  font-size: 2rem;
  font-weight: 300;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
}

.home-title {
  font-size: 5rem;
  font-weight: 800;
  text-align: left;
  margin-top: 10px;

  @media (max-width: 768px) {
    font-size: 3rem;
    text-align: center;
  }
}

.home-description {
  font-size: 1.5rem;
  font-weight: 400;
  margin-top: 20px;
  color: $blue-color;

  @media (max-width: 768px) {
    font-size: 1.2rem;
    margin-top: 15px;
    text-align: center;
  }
}

.home-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;

  @media (max-width: 768px) {
    width: 100%;
    margin-top: 20px;
  }
}

.home-image {
  max-width: 100%;
  height: auto;
  border-radius: 50%;

  @media (max-width: 768px) {
    max-width: 200px;
    border-radius: 50%;
  }
}

.sub-typed-text {
  font-size: 2rem;
  font-weight: 500;
  margin-top: 20px;
  min-height: 50px;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }

  span {
    min-height: 22svh; /* Adjusted to be 55vh for consistent viewport height */
    display: flex; /* Ensures proper behavior for span elements */
    justify-content: center;
    align-items:center;
    @media (max-width: 768px) {
      min-height: 18svh;
      font-size: 3rem;
    }
  }
}
