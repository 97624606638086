@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    background: transparent;
    font-family: "Poppins", sans-serif
}
  
body {
    color: rgb(30, 30, 30);
    overflow: hidden;
    height: 100%;
    width: 100%;   
    background-color: #f9f9f9; 
}

html {
    scroll-behavior: smooth;
    height: 100%;
    width: 100%;
}
.root {
    height: 100%;
    width: 100%;
}

/* width */
::-webkit-scrollbar {
    width: 4px;
    border-radius: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: rgb(30, 30, 30);;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
